<template>
	<article
		:class="{
			'project_mobile': mobileMode,
			'project_desktop': !mobileMode,
		}"
		class="project"
	>
		<header class="project__description-wrapper">
			<h1 class="project-title" v-html="title" />
			<div class="project__details-wrapper">
				<template
					v-for="(group, index) in projectActualGroups"
				>
					<div
						v-if="filterProjectTagsByGroup(group).length"
						:key="`${index}-detail`"
						class="project-detail"
					>
						<h3 class="project-subtitle detail-title" v-html="getTagGroup(group).title" />
						<tag-list
							:tags="filterProjectTagsByGroup(group)"
							:clickable="true"
							separator="/"
							class="detail-description"
						/>
					</div>
				</template>

				<div v-if="dates" class="project-detail">
					<h3 class="project-subtitle detail-title" v-html="$t('date')" />
					<p class="detail-description">
						<template
							v-for="(date, index) in dates"
						>
							<span
								:key="`date-${index}`"
								class="nobr"
								v-html="date"
							/>
							<template v-if="index < dates.length - 1">, </template>
						</template>
					</p>
				</div>
			</div>

			<div v-if="fullProject.description" class="project__description">
				<h3 class="project-subtitle">{{ $t('description') }}</h3>
				<div class="project-description__description" v-html="fullProject.description" />
			</div>
		</header>
		<grid-layout
			v-if="tiles.length"
			:tiles="tiles"
			:cellHeight="cellHeight"
			class="project__gallery"
		>
			<social-sharing-links
				class="project__social-sharing"
				:description="title"
				:quote="shareQuote"
				:title="title"
				:tags="project.tags"
				:media="project.image.meta.download_url"
			/>
		</grid-layout>
	</article>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import GridLayout from '@/components/grid-layout.vue';
import TagList from '@/components/tag-list.vue';
import SocialSharingLinks from '@/components/social-sharing-links.vue';
import { removeHTMLTags, cleanOfBlockTags, formatDate } from '@/utilites.js';

export default {
	name: 'Project',
	data() {
		return {
			fullProject: this.project,
			cellHeight: 405,
			isLoading: false,
		};
	},
	components: {
		GridLayout,
		TagList,
		SocialSharingLinks
	},
	props: {
		project: {
			type: Object,
			required: true,
		},
		shrink: {
			type: Boolean,
			default: false,
		},
	},


	computed: {
		...mapGetters('browser', ['mobileMode']),
		...mapState('tags', ['tags', 'groups', 'defaultGroup']),

		title() {
			return cleanOfBlockTags(this.project.title)
				.replace(/^\s*(<br\s?\/?>)+|(<br\s?\/?>)+\s*$/i, '');
		},

		projectTags() {
			return this.fullProject.tags.map(slug => this.tags.find(tag => tag.slug === slug));
		},

		projectActualGroups() {
			return [this.defaultGroup];
		},

		tiles() {
			if (!this.fullProject.block_media) return [];
			const tileTypeMapping = {
				image: 'image',
				video: 'video',
				text: 'text',
			};
			return this.fullProject.block_media.map(tile => {
				const cellWH = tile.value.size.split('x');
				return {
					type: tileTypeMapping[tile.type],
					data: {
						...tile,
						cell: {
							w: parseInt(cellWH[0]),
							h: parseInt(cellWH[1]),
						},
					},
				};
			});
		},

		dates() {
			if (!this.fullProject.project_time || !this.fullProject.project_time.length) return null;

			return this.fullProject.project_time.map(date => formatDate(date).split('/').join(`<span style="margin:0 .5em;">/</span>`));
		},

		shareQuote() {
			return removeHTMLTags(this.fullProject.description);
		},
	},


	methods: {
		...mapActions('tags', ['getTags']),
		...mapActions('projects', ['getProject']),

		getTagGroup(slug) {
			return this.groups.find(group => group.slug === slug);
		},

		filterProjectTagsByGroup(slug) {
			return this.projectTags.filter(tag => tag.group === slug);
		},

		async refreshFullProject() {
			this.isLoading = true;
			try {
				const fullProject = await this.getProject(this.project.id);
				this.$set(this, 'fullProject', Object.freeze(fullProject));
			} catch (error) {
				console.error(error);
			}
			this.isLoading = false;
		}
	},


	watch: {
		project() {
			this.refreshFullProject();
		},
	},

	created() {
		if (this.tags.length === 0) {
			this.getTags();
		}

		this.refreshFullProject();
	},
};
</script>


<style lang="sass" scoped>
.project
	position:relative


.project__description-wrapper
	box-sizing: border-box
	max-width: 100%
	overflow: hidden

	.project_mobile &
		margin: rslv($p-work__descr-pad-top__mob) auto rslv($p-work__descr-pad-btm__mob)
		padding-left: rslv($pad__x__mob)
		padding-right: rslv($pad__x__mob)

	.project_desktop &
		margin: rslv($p-work__descr-pad-top) auto rslv($p-work__descr-pad-btm)
		padding-left: rslv($pad__x)
		padding-right: rslv($pad__x)

.project-title
	padding: 0
	display: inline-block

	.project_mobile &
		margin: 0 0 #{rslv($p-work__title-mgn-btm__mob)} 0
		font-size: rslv($fs__default-title__mob)
		line-height: $lh__default-title__mob

	.project_desktop &
		font-size: rslv($p-work__title-fs)
		line-height: $p-work__title-lh
		margin: 0 0 #{rslv($p-work__title-mgn-btm)} 0



.project-subtitle
	margin: 0
	padding: 0
	font-family: inherit

	.project_mobile &
		font-size: rslv($fs__default-text__mob)
		line-height: $lh__default-text__mob
		color: $clr__subtitle__mob
		font-weight: bold

	.project_desktop &
		font-size: rslv($fs__default-text)
		line-height: $lh__default-text
		color: $clr__subtitle
		font-weight: normal


.project-description__description,
.detail-description
	display: block
	margin: 0
	padding: 0
	font-family: inherit
	color: $clr__def
	font-weight: normal

	.project_mobile &
		@extend %p-mobile

	.project_desktop &
		@extend %p-desktop

	&::v-deep
		p
			margin: 0

			&:empty
				display: none


.project__details-wrapper
	max-width: 100%
	display: flex
	flex-direction: row
	flex-wrap: wrap
	align-items: flex-start

	.project_mobile &
		margin-bottom: rslv($p-work__det-pad-btm__mob - $pad__x__mob)
		justify-content: space-between

	.project_desktop &
		margin-bottom: rslv($p-work__descr-pad-btm)
		justify-content: space-between

.project-description__description,
.project__details-wrapper
	@media screen and (min-width: 4.5*330px)
		max-width: calc(100vw / 5 * 4 - 45px)

	@media screen and (min-width: 5.5*330px)
		max-width: calc(100vw / 6 * 4 - 45px)

	@media screen and (min-width: 6.5*330px)
		max-width: calc(100vw / 7 * 4 - 45px)

	@media screen and (min-width: 7.5*330px)
		max-width: calc(100vw / 8 * 4 - 45px)

	@media screen and (min-width: 8.5*330px)
		max-width: calc(100vw / 9 * 4 - 45px)

	@media screen and (min-width: 9.5*330px)
		max-width: calc(100vw / 10 * 4 - 45px)



.project-detail
	display: inline-block
	// margin-top: rslv($p-work__det-gap)

	.project_mobile &
		min-width: calc(50% - 50px)
		max-width: calc(100% - 50px)
		margin-bottom: 25px

	.project_desktop &
		width: calc(50% - 40px)
		margin-right: 40px
		margin-bottom: 40px


.project__gallery
	position: relative

.project__social-sharing
	position: absolute
	z-index: 1
	top: calc((100% - 250px) / 2)
	left: 0
	bottom: calc((100% - 250px) / 2)
</style>
