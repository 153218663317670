<template>
	<article
		:class="{
			'next-project_mobile': mobileMode,
			'next-project_desktop': !mobileMode,
		}"
		class="next-project"
	>
		<header class="next-project__description-wrapper">
			<button
				type="button"
				role="link"
				class="next-project__button"
				v-html="title"

				@click="$emit('click')"
			/>
		</header>
	</article>
</template>

<script>
import { mapGetters } from 'vuex';
import { cleanOfBlockTags } from '@/utilites.js';

export default {
	name: 'NextProjectHeader',

	props: {
		project: {
			type: Object,
			required: true,
			validator(value) {
				return value.meta.slug;
			},
		},
	},


	computed: {
		...mapGetters('browser', ['mobileMode']),

		title() {
			return cleanOfBlockTags(this.project.title)
				.replace(/^\s*(<br\s?\/?>)+|(<br\s?\/?>)+\s*$/i, '');
		},
	},
};
</script>


<style lang="sass" scoped>
.next-project
	position:relative
	overflow: hidden

.next-project_mobile
	height: rslv($p-work__proj-shrinked-h__mob)

.next-project_desktop
	height: rslv($p-work__proj-shrinked-h)

.next-project__description-wrapper
	box-sizing: border-box
	max-width: 100%
	overflow: hidden

	.next-project_mobile &
		padding: 0 rslv($pad__x__mob)

	.next-project_desktop &
		padding: 0 rslv($pad__x)

.next-project__button
	@extend %clean-button
	font-weight: unset
	font-family: $font__avant
	text-align: left
	padding: 0
	display: inline-block
	cursor: pointer

	.next-project_mobile &
		font-size: rslv($fs__default-title__mob)
		line-height: $lh__default-title__mob

	.next-project_desktop &
		font-size: rslv($p-work__title-fs)
		line-height: $p-work__title-lh
</style>
