<template>
	<button
		type="button"
		role="link"
		:class="{
			'mobile': mobileMode,
			'desktop': !mobileMode,
			'back': finish,
		}"
		class="npb"
		@click.prevent="clickHandler"
	>
		<span class="caption" v-html="caption" />
		<span v-if="nextProject" class="title" v-html="nextProjectTitle" />
		<svg class="arrow"
			width="16"
			height="80"
			viewBox="0 0 16 80"
		>
			<use
				width="80"
				height="16"
				x="-32"
				y="32"
				transform-origin="center"
				transform="rotate(90)"
				xlink:href="#glyph--arrow"
				href="#glyph--arrow"
			/>
		</svg>
		<!-- <span class="arrow" /> -->
	</button>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import { cleanOfBlockTags } from '@/utilites.js';

export default {
	name: 'NextProjectButton',

	data() {
		return {};
	},

	props: {
		nextProject: {
			type: Object,
			required: false,
			default: null,
		},
	},

	computed: {
		...mapState(['route']),
		...mapGetters('browser', ['mobileMode']),
		nextProjectTitle() {
			if (this.nextProject) return cleanOfBlockTags(this.nextProject.title);
			else return null;
		},

		finish() {
			return this.nextProject === null;
		},

		filter() {

		},

		caption() {
			let caption = this.finish ? this.$t('to_the_top') : this.$t('next_project');
			return caption;
		},
	},

	methods: {
		clickHandler($event) {
			let eventName = this.nextProject ? 'go-to-next' : 'go-to-first';
			this.$el.blur();
			this.$emit(eventName);
		},
	},
};
</script>


<style lang="sass" scoped>
.npb
	@extend %clean-button
	display: inline-flex
	position: relative
	margin: 0 auto
	text-decoration: none
	color: inherit
	flex-direction: column
	align-content: center
	cursor: pointer

.mobile
	padding-top: rslv($pad__x__mob)
	padding-right: rslv($pad__x__mob)
	padding-bottom: rslv($pad__x__mob * 2 + $crd__short-arrow-height)
	padding-left: rslv($pad__x__mob)

.desktop
	padding: rslv($pad__x)
	padding-right: rslv($pad__x)
	padding-bottom: rslv($npb__padding-bottom)
	padding-left: rslv($pad__x)

.back
	padding-top: rslv($npb__padding-bottom)
	padding-right: rslv($pad__x)
	padding-bottom: rslv($pad__x)
	padding-left: rslv($pad__x)

.caption
	display: block
	padding: 0
	margin: 0 auto
	color: $clr__subtitle
	font-weight: normal
	text-align: center

	.mobile &
		@extend %p-mobile

	.desktop &
		@extend %p-desktop

.title
	display: block
	padding: 0
	color: inherit
	font-family: $font__avant
	line-height: 1
	text-align: center

	.mobile &
		font-size: rslv($fs__small-card-title)
		margin-top: rslv($pad__x__mob)

	.desktop &
		font-size: rslv($fs__card-title)
		margin-top: rslv($npb__caption-title-gap)

.arrow
	position: absolute
	left: calc(50% - #{rslv($crd__arrow-width / 2)})
	display: block
	// background-image: url('~@/assets/images/long-arrow-black.svg')
	// background-repeat: no-repeat
	// background-size: auto 100%
	// background-position: center bottom
	width: rslv($crd__arrow-width)
	color: black

	.mobile &
		height: rslv($crd__short-arrow-height)
		bottom: rslv($pad__x__mob)

	.desktop &
		bottom: rslv($npb__arrow-bottom)
		height: rslv($crd__arrow-height)

	.back &
		transform: rotate(180deg)
		bottom: unset
		top: rslv($npb__arrow-bottom)
</style>
