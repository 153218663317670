<template>
	<section
		:class="{
			'work-page_mobile': mobileMode,
			'work-page_desktop': !mobileMode,
		}"
		class="work-page"
	>
		<project
			v-for="(project, index) in projectsToShow"
			:id="project.meta.slug"
			:key="project.meta.slug"
			:ref="`projects`"
			v-is-on-screen:y="{ handler: isOnScreenHandler, data: project.meta.slug }"
			:project="project"
			class="work-page__project"
		/>
		<next-project-button
			v-if="firstProject"
			:first-project="firstProject"
			:next-project="nextProject"
			class="work-page__next-project-button"
			@go-to-next="openNextProject"
			@go-to-first="goToTheTop"
		/>
		<next-project-header
			v-if="!mobileMode && nextProject"
			:project="nextProject"
			@click="openNextProject"
		/>
	</section>
</template>

<script>
import { ALL_PROJECTS_ROUTE_NAME } from '@/constants/router-strings.js';
import { mapState, mapGetters, mapActions } from 'vuex';
import Project from '@/components/work/project.vue';
import NextProjectButton from '@/components/work/next-project-button.vue';
import NextProjectHeader from '@/components/work/next-project-header.vue';

export default {
	name: 'WorkPage',


	data() {
		return {
			detailsTagGroups: ['brand', 'offering'],
			projectsCount: 1,
			initialProjectSlug: null,
			currentProjectSlug: null,
		};
	},


	components: {
		Project,
		NextProjectButton,
		NextProjectHeader,
	},


	computed: {
		...mapState('projects', { storedProjects: 'list' }),
		...mapState(['route']),
		...mapState('page', ['language']),
		...mapState('tags', ['tags', 'defaultGroup']),
		...mapGetters('browser', ['mobileMode']),



		projects() {
			const projects = this.storedProjects;
			const filterTag = this.route.query[this.defaultGroup];
			if (!filterTag) {
				return projects;
			}
			return projects.filter(project => project.tags.includes(filterTag));
		},


		firstProject() {
			return this.projects.find(project => project.meta.slug === this.initialProjectSlug);
		},


		initialProjectIndex() {
			const index = this.projects.indexOf(this.firstProject);
			if (index === -1) return null;
			return  index;
		},


		projectsToShow() {
			return this.projects.slice(this.initialProjectIndex, this.initialProjectIndex + this.projectsCount);
		},

		nextProject() {
			const index = this.initialProjectIndex + this.projectsCount;
			if (index >= this.projects.length) return null;
			return this.projects[index];
		},
	},


	methods: {
		...mapActions('projects', ['getProjects']),
		...mapActions('tags', ['getTags']),
		setInitialProjectSlug(slug) {
			this.initialProjectSlug = slug || this.route.params.slug;
			this.currentProjectSlug = slug || this.route.params.slug;
		},

		openNextProject() {
			if (!this.nextProject) return null;
			this.projectsCount++;
			this.$nextTick(() => {
				// Scroll to the fresh project !
				const lastProjectEl = this.$refs.projects.slice(-1).pop().$el;
				window.scrollTo({
					left: lastProjectEl.offsetLeft,
					top: lastProjectEl.offsetTop,
					behavior: 'smooth',
				});
			});
		},

		goToTheTop() {
			window.scrollTo({
				left: 0,
				top: 0,
				behavior: 'smooth',
			});
		},

		isOnScreenHandler(slug) {
			if (this.currentProjectSlug !== slug) {
				this.currentProjectSlug = slug;
				this.$router.replace({
					params: {
						slug,
						routeByScroll: true,
					},
					query: this.route.query,
				});
			}
		},
	},


	watch: {
		storedProjects: {
			deep: true,
			handler(current, previous) {
				const index = current.findIndex(project => project.meta.slug === this.currentProjectSlug);
				if (index === -1) {
					this.$router.replace({
						name: ALL_PROJECTS_ROUTE_NAME,
						query: this.route.query,
					});

					return;
				}
				this.projectsCount = 1;
				this.initialProjectSlug = this.currentProjectSlug;

				/*
				 * prevent-body-scroll directive blocks scrolling
				 * TODO: scroll to the top on preloader hide
				 */
			},
		},
	},

	// hooks
	created() {
		if (this.projects.length === 0) {
			this.getProjects();
		}

		if (this.tags.length === 0) {
			this.getTags();
		}

		this.setInitialProjectSlug();
	},
};
</script>

<style lang="sass">
.work-page
	display: flex
	flex-direction: column
	align-items: stretch

// .work-page_mobile

.work-page_desktop
	padding: rslv($hp__height) 0 0

.work-page__project
	&:not(:first-child)
		animation-name: project-bump-up
		animation-duration: .75s
		animation-iteration-count: 1
		animation-timing-function: ease

	.work-page_mobile &
		padding-top: rslv($hp__height__mob)

	.work-page_desktop &
		&:not(:last-child)
			margin-bottom: rslv($hp__height)

		@keyframes project-bump-up
			from
				transform: translate3d(0, rslv($p-work__proj-margin-top), 0)
</style>
